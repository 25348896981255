import * as React from 'react'

const fallbackImagePath = '/images/therich.png'
const handleImageError = (
  error: React.SyntheticEvent<HTMLImageElement, Event>,
  replaceImage: string = fallbackImagePath,
) => {
  const imageEl = error.target as HTMLImageElement
  if (!imageEl.src.includes(replaceImage)) {
    imageEl.src = replaceImage
  }
}

type Props = {
  hasLogoOnError?: boolean
  replaceImage?: string
  onImageError?: () => void
} & React.ImgHTMLAttributes<HTMLImageElement>

const ImageWithFallback = ({
  hasLogoOnError = false,
  replaceImage,
  onImageError,
  ...imageProps
}: Props) => {
  const handleError = React.useCallback(
    (error: React.SyntheticEvent<HTMLImageElement, Event>) => {
      if (hasLogoOnError || replaceImage) {
        handleImageError(error, replaceImage) // 더리치 로고로 대체
      } else {
        // 이미지 숨김
        ;(error.target as HTMLImageElement).style.display = 'none'
      }
      onImageError && onImageError()
    },
    [hasLogoOnError, onImageError, replaceImage],
  )

  return (
    <img
      loading="lazy"
      onError={handleError}
      alt={imageProps.alt}
      {...imageProps}
      referrerPolicy="no-referrer"
    />
  )
}

export default ImageWithFallback
