import * as React from 'react'

import {
  InvestingInsightTagFieldsFragment,
  useInvestingInsightTagsQuery,
} from '@/gql/generated-api1'
import useIntersectionPagination from '@/utils/intersection-pagination-hook'

import useInvestingInsights from './investing-insights-hook'

const LIMIT = 20

type Props = {
  investingInsightsTagId?: string // 있을 경우 태그 요청과 별개로 컨텐츠 요청
  onTagClick?: (tagId: InvestingInsightTagFieldsFragment['id']) => void
}

export default function useInvestingInsightsWithTags({
  investingInsightsTagId,
  onTagClick,
}: Props = {}) {
  const [tagId, setTagId] = React.useState<InvestingInsightTagFieldsFragment['id'] | null>(null)
  const { data, loading, fetchMore } = useInvestingInsightTagsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      page: 0,
      limit: LIMIT,
    },
  })
  const { moreLoadingElRef, isShowMoreLoading } = useIntersectionPagination<HTMLLIElement, number>({
    items: data?.investingInsightTags,
    loading,
    lengthPerPage: LIMIT,
    handleFetchMore: React.useCallback(
      (nextPage) => fetchMore({ variables: { page: nextPage } }),
      [fetchMore],
    ),
  })
  const handleTagClick = React.useCallback(
    (tagId: InvestingInsightTagFieldsFragment['id']) => {
      setTagId(tagId)
      onTagClick && onTagClick(tagId)
    },
    [onTagClick],
  )
  const investingInsightsProps = useInvestingInsights({
    tagId: investingInsightsTagId || tagId || '',
  })
  const sortedTags = React.useMemo(() => {
    if (!data?.investingInsightTags) return undefined
    return [...data.investingInsightTags].sort((a, b) => a.sortId - b.sortId)
  }, [data?.investingInsightTags])

  // 최초 렌더시 첫번째 태그로 설정
  React.useEffect(() => {
    if (!tagId && data?.investingInsightTags) {
      const tagId = data?.investingInsightTags[0].id
      if (tagId) {
        setTagId(tagId)
      }
    }
  }, [data?.investingInsightTags, tagId])

  React.useEffect(() => {
    if (investingInsightsTagId && data?.investingInsightTags) {
      const tag = data?.investingInsightTags.find((tag) => tag.id === investingInsightsTagId)
      if (tag) {
        setTagId(tag.id)
      }
    }
  }, [investingInsightsTagId, data?.investingInsightTags])

  return {
    tagId,
    handleTagClick,
    investingInsightTagsProps: {
      investingInsightTags: sortedTags,
      loading,
      moreLoadingElRef,
      isShowMoreLoading,
    },
    investingInsightsProps,
  }
}
