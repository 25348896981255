import * as React from 'react'

import { InvestingInsightFieldsFragment } from '@/gql/generated-api1'
import useLanguage from '@/utils/language/language-hook'

import InvestingInsightsContent, {
  InvestingInsightsContentSkeleton,
} from '../investing-insights-content'

import StyledInvestingInsightsContents from './styled'

type Props = {
  loading?: boolean
  investingInsights?: InvestingInsightFieldsFragment[]
  isShowMoreLoading?: boolean
  moreLoadingElRef?: React.RefObject<HTMLLIElement>
}

const InvestingInsightsContents = ({
  loading,
  investingInsights,
  isShowMoreLoading,
  moreLoadingElRef,
}: Props) => {
  const { t } = useLanguage('investing-insights')
  return (
    <StyledInvestingInsightsContents>
      {/* contents 없는 경우 */}
      {!loading && !investingInsights?.length && (
        <div className="--flex-x-y-center no-contents">
          <p>{t('noContents')}</p>
        </div>
      )}

      <ul className="contents-container">
        {loading && !investingInsights?.length && (
          <li>
            <InvestingInsightsContentSkeleton />
          </li>
        )}

        {investingInsights?.map((investingInsight) => (
          <li key={investingInsight.id}>
            <InvestingInsightsContent investingInsight={investingInsight} />
          </li>
        ))}

        {/* more loading skeleton */}
        {!loading && isShowMoreLoading && (
          <li ref={moreLoadingElRef}>
            <InvestingInsightsContentSkeleton />
          </li>
        )}
      </ul>
    </StyledInvestingInsightsContents>
  )
}

export default InvestingInsightsContents
