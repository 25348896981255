import * as React from 'react'

import { useMobileSize } from '@/utils/browser/resize-hook'
import useClassName from '@/utils/array/class-name-hook'

import { SpaceSize } from '../type'

type Props = {
  size: SpaceSize
  className?: string
}

const withMobileOnly = <T,>(Component: React.FC<T>) => {
  // eslint-disable-next-line react/display-name
  return (props: T & { mobileOnly?: boolean; className?: string }) => {
    const { mobileOnly } = props
    const cname = useClassName([mobileOnly && '--mobile-only', props.className])
    return <Component {...props} className={cname} />
  }
}

function sizeVariable(size: SpaceSize) {
  return `var(--spacing-legacy-${size})`
}

export const Spacer = ({ size, className }: Props) => {
  return (
    <div className={className} style={{ width: sizeVariable(size), height: sizeVariable(size) }} />
  )
}
export const SpacerX = withMobileOnly(({ size, className }: Props) => {
  return <div className={className} style={{ marginLeft: sizeVariable(size) }} />
})
export const SpacerY = ({ size, className }: Props) => {
  return <div className={className} style={{ marginTop: sizeVariable(size) }} />
}

/**
 * 컴포넌트 좌우 margin or padding 으로 사용할 수 있도록 고안된 spacer
 */
export const SideSpacer = ({
  size,
  padding,
  children,
  mobileOnly,
  className,
}: Props & {
  padding?: boolean
  children?: React.ReactNode
  mobileOnly?: boolean
  className?: string
}) => {
  const { isMobile } = useMobileSize()
  const style = React.useMemo(
    () => ({ [padding ? 'padding' : 'margin']: `0 ${sizeVariable(size)}` }),
    [padding, size],
  )

  return mobileOnly && !isMobile ? (
    <>{children}</>
  ) : (
    <div style={style} className={className}>
      {children}
    </div>
  )
}
