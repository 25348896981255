import * as React from 'react'

import { ExternalLink } from '@/components/common/link'

type Props = {
  children: React.ReactNode
  href: string
  onClick: () => void
}

const InvestingInsightsLink = ({ children, href, onClick }: Props) => {
  return (
    <ExternalLink
      href={href || '#'}
      target="_blank"
      rel="noopener noreferrer nofollow"
      onClick={onClick}
      gaCategory="INVESTING-INSIGHTS"
      gaAction="INVESTING-INSIGHTS_ITEM_CLICK"
      gaLabel={`INVESTING-INSIGHTS_ITEM_${href}`}>
      {children}
    </ExternalLink>
  )
}

export default InvestingInsightsLink
