import styled from 'styled-components'

const StyledInvestingInsightsNav = styled.ul`
  li {
    :not(:first-child) {
      margin-left: var(--spacing-legacy-4);
    }
  }
`

export default StyledInvestingInsightsNav
