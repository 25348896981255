import * as React from 'react'

import { useInvestingInsightsQuery } from '@/gql/generated-api1'
import useLanguage from '@/utils/language/language-hook'
import useIntersectionPagination from '@/utils/intersection-pagination-hook'

type Props = {
  tagId: string
}

const LIMIT = 20

export default function useInvestingInsights({ tagId }: Props) {
  const { language } = useLanguage()
  const { data, fetchMore, loading } = useInvestingInsightsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { language, tagId, page: 0, limit: LIMIT },
    skip: !tagId,
    notifyOnNetworkStatusChange: true,
  })
  const { moreLoadingElRef, isShowMoreLoading } = useIntersectionPagination<HTMLLIElement, number>({
    items: data?.investingInsights,
    loading,
    lengthPerPage: LIMIT,
    handleFetchMore: React.useCallback(
      (nextPage) => fetchMore({ variables: { page: nextPage } }),
      [fetchMore],
    ),
  })

  return {
    investingInsights: data?.investingInsights,
    loading,
    moreLoadingElRef,
    isShowMoreLoading,
  }
}
