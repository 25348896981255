import * as React from 'react'

import { InvestingInsightFieldsFragment } from '@/gql/generated-api1'

type Props = {
  investingInsight: InvestingInsightFieldsFragment
}

export default function useDetailViewLink({ investingInsight }: Props) {
  return React.useMemo(() => {
    const originUrl = new URL(investingInsight.originalUrl)
    const [originWithoutQuery] = originUrl.search
      ? originUrl.href.split(originUrl.search)
      : [originUrl.href]
    const originSearchParams = new URLSearchParams(originUrl.search)
    originSearchParams.set('utm_source', 'therich')
    originSearchParams.set('utm_medium', 'therich')
    return originWithoutQuery + '?' + originSearchParams.toString()
  }, [investingInsight.originalUrl])
}
