import styled from 'styled-components'

const StyledInvestingInsightsContents = styled.div`
  > .contents-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: var(--spacing-legacy-50) var(--spacing-legacy-40);

    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: var(--spacing-legacy-28) var(--spacing-legacy-20);
    }

    .headline {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }

    > li {
      display: inline-block;
      min-width: 0; /* 컨텐츠 overflow 되도 1/4 크기로 고정 */
    }
  }

  > .no-contents {
    text-align: center;
    min-height: calc(var(--spacing-legacy-5) * 46);

    p {
      font-size: var(--font-size-15);
      line-height: 1.5;
    }
  }
`

export default StyledInvestingInsightsContents
