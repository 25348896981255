import * as React from 'react'
import { Button } from '@therich-team/design-system'

import { InvestingInsightTagFieldsFragment } from '@/gql/generated-api1'
import { useGetByLocaleName } from '@/utils/language'

type Props = {
  active: boolean
  investingInsightTag: InvestingInsightTagFieldsFragment
  onClick: (tagId: InvestingInsightTagFieldsFragment['id']) => void
}

const InvestingInsightsNavItem = ({ active, investingInsightTag, onClick }: Props) => {
  const { names } = investingInsightTag
  const getByLocaleName = useGetByLocaleName()
  const localeName = React.useMemo(() => getByLocaleName(names), [getByLocaleName, names])
  return (
    <Button
      variant="territory"
      size="small"
      active={active}
      onClick={() => onClick(investingInsightTag.id)}>
      {localeName}
    </Button>
  )
}

export default InvestingInsightsNavItem
