import * as React from 'react'
import { MutationResult } from '@apollo/client'

import {
  ClickInvestingInsightMutation,
  ServicePlatformEnum,
  useClickInvestingInsightMutation,
} from '@/gql/generated-api1'
import { isMobileOrTablet } from '@/utils/browser/detection'

export default function useClickInvestingInsight(): [
  (props: { investingInsightId: string }) => void,
  MutationResult<ClickInvestingInsightMutation>,
] {
  const [_clickInvestingInsight, resp] = useClickInvestingInsightMutation()
  const clickInvestingInsight = React.useCallback(
    ({ investingInsightId }: { investingInsightId: string }) => {
      _clickInvestingInsight({
        variables: {
          investingInsightId,
          platform: isMobileOrTablet() ? ServicePlatformEnum.MobileWeb : ServicePlatformEnum.PcWeb,
        },
      })
    },
    [_clickInvestingInsight],
  )
  return [clickInvestingInsight, resp]
}
