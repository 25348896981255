import * as React from 'react'

import { useAuthTokenStore } from '@/service/auth/store'

/**
 * GQL api 중 페이지네이션이 가능한 쿼리들을 기반으로 만든 페이지네이션 쿼리 용 hook
 *
 * items: 빈배열로 할당하지 말것(초기 마운트 refetch 필요한 경우를 감지 할 수 있도록 nullish 값으로 할당해야함)
 * handleFetchMore: startDate나 page를 매개변수로 받아 fetchMore 실행을 구현한 함수를 할당
 * startDate: 값이 있을 경우 startDate 기반으로 작동. startDate 없다면 page 기반으로 작동(페이지네이션 쿼리 variables 참고)
 * initRefetch: 최초 렌더시 캐시값이 있을 경우 네트워크 데이터 요청하는 역할. refetch 구현 함수를 할당
 * initRefetchKey, initRefetchKeys: 쿼리 variables에 따라서 refetch 조건을 달리해야할 경우 사용(variables 변화에 데이터를 신경쓸 필요가 없다면 사용하지 않아도됨)
 */
export default function useIntersectionPagination<
  MoreLoadingElementType extends HTMLElement,
  HandleFetchMoreProp extends string | number = string,
>({
  items,
  loading,
  lengthPerPage,
  startDate,
  handleFetchMore,
  initRefetchKey,
  initRefetchKeys = [],
  initRefetch,
  rootRef,
  rootMargin = '0px 0px 800px 0px', // intersection target element 위치보다 800px 위에서 감지(px, % 단위만 가능)
}: {
  items?: unknown[] | null
  loading: boolean
  lengthPerPage: number
  startDate?: string
  handleFetchMore: (nextPageVariable: HandleFetchMoreProp extends string ? string : number) => void
  initRefetchKey?: string | null
  initRefetchKeys?: string[]
  initRefetch?: (prop: Record<string, unknown>) => void
  rootRef?: React.RefObject<HTMLElement>
  rootMargin?: string
}) {
  const { token } = useAuthTokenStore()
  const itemsLengthRef = React.useRef(0) // fetchMore을 위한 ref로 refetch시 itemsLengthRef.current = 0 할당 필요. 현재 아이템 개수를 알고 싶다면 이 ref가 아닌 items 참조할것
  const moreLoadingElRef = React.useRef<MoreLoadingElementType>(null)
  const isInitRefetchByKey = React.useMemo(
    () =>
      initRefetchKeys.reduce((acc: Record<string, boolean>, key) => {
        acc[key] = false
        return acc
      }, {}),
    [initRefetchKeys],
  )
  const isInitRefetchByKeyRef = React.useRef<Record<string, boolean>>(isInitRefetchByKey)

  const isShowMoreLoading =
    (items && itemsLengthRef.current + lengthPerPage <= items?.length) || // 마지막 fetch 피드 갯수가 fetch 전 피드 갯수 + 페이지당 갯수(LIMIT)와 같거나 클 경우 로딩바 감춤으로써 intersaction 이벤트 호출 못하도록 조치(마지막 페이지)
    (itemsLengthRef.current !== 0 && loading)

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!loading) {
              observer.unobserve(entry.target)
              const itemsLength = items?.length ?? 0
              itemsLengthRef.current = itemsLength // 이전 피드 갯수 기록
              const nextPageVariable = startDate ?? Math.ceil(itemsLength / lengthPerPage)
              nextPageVariable &&
                handleFetchMore(
                  nextPageVariable as HandleFetchMoreProp extends string ? string : number,
                )
            }
          }
        })
      },
      { root: rootRef?.current, rootMargin },
    )

    moreLoadingElRef.current && observer.observe(moreLoadingElRef.current)

    return () => {
      observer.disconnect()
    }
  }, [
    rootRef,
    rootMargin,
    loading,
    itemsLengthRef,
    handleFetchMore,
    moreLoadingElRef,
    startDate,
    items,
    lengthPerPage,
  ])

  React.useEffect(() => {
    // items.length로 체크하면 기존 캐시 길이가 0일 경우 refetch 못함
    if (!initRefetchKey && items && initRefetch) {
      itemsLengthRef.current = 0
      initRefetch({ token })
    }
    // 의존성 제한하는 refetch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  React.useEffect(() => {
    if (initRefetchKey) {
      // 의존성 있을 경우 해당 키값을 통해서 아직 refetch 안한 상태일 경우 트리거
      if (!isInitRefetchByKeyRef.current[initRefetchKey]) {
        isInitRefetchByKeyRef.current[initRefetchKey] = true
        if (items && initRefetch) {
          itemsLengthRef.current = 0
          initRefetch({ token })
        }
      }
    }
    // 최초 렌더시에만 실행해야 하므로 의존성 제한
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initRefetchKey, initRefetch, token])

  return {
    isShowMoreLoading,
    itemsLengthRef,
    moreLoadingElRef,
  }
}
