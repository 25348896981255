import styled from 'styled-components'

/**
 * 데탑 너비에서는 카드 아이템 그리드 리스트 레이아웃으로 보여지고
 * 모발 너비에서는 플랫 아이템 수직 리스트 레이아웃으로 보여지는 경우 사용
 */
export const StyledInvestingInsightsContent = styled.div`
  .title {
    color: var(--color-gray-197);
    font-size: var(--font-size-15);
    line-height: 1.4;
    -webkit-line-clamp: 2;
  }

  .body {
    color: var(--color-gray-128);
    font-size: var(--font-size-14);
    line-height: 1.4;
    word-break: break-all;
    -webkit-line-clamp: 2;
  }

  .sub-text {
    .platform-image {
      display: inline-block;
      width: var(--spacing-legacy-20);
      height: var(--spacing-legacy-20);
      border-radius: 50%;
      overflow: hidden;
    }

    span {
      display: inline-block;
      color: var(--color-gray-158);
      font-size: var(--spacing-legacy-13);
      word-break: break-word;
    }

    .separator {
      margin: 0 var(--spacing-legacy-5);
    }
  }

  .action-buttons {
    margin-top: var(--spacing-legacy-10);
    > * {
      :not(:last-child) {
        margin-right: var(--spacing-legacy-5);
      }
    }
  }

  > .image {
    position: relative;
    width: 100%;
    aspect-ratio: 163 / 120;

    img {
      object-fit: cover;
      border-radius: var(--border-radius-12);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
`

export default StyledInvestingInsightsContent
