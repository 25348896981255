import * as React from 'react'

import { InvestingInsightFieldsFragment } from '@/gql/generated-api1'
import ImageWithFallback from '@/components/common/image-with-fallback'
import Skeleton from '@/components/common/skeleton'
import Image from '@/components/common/image'
import { SpacerX, SpacerY } from '@/design-system/spacer'
import useClickInvestingInsight from '@/gql/investing-insight/click-investing-insight-hook'
import useDetailViewLink from '@/service/investing-insights/detail-view-link-hook'

import InvestingInsightsLink from '../investing-insights-link'

import StyledInvestingInsightsContent from './styled'

type Props = {
  investingInsight: InvestingInsightFieldsFragment
}

const InvestingInsightsContent = ({ investingInsight }: Props) => {
  const [clickInvestingInsight] = useClickInvestingInsight()
  const handleClick = React.useCallback(() => {
    clickInvestingInsight({ investingInsightId: investingInsight.id })
  }, [clickInvestingInsight, investingInsight.id])
  const originalUrlWithUtm = useDetailViewLink({ investingInsight })

  return (
    <StyledInvestingInsightsContent>
      <div className="--flex-shrink-off image">
        <InvestingInsightsLink href={originalUrlWithUtm} onClick={handleClick}>
          <ImageWithFallback
            src={investingInsight.thumbnailImageUrl}
            alt={investingInsight.title}
          />
        </InvestingInsightsLink>
      </div>
      <SpacerY size={16} />
      <InvestingInsightsLink href={originalUrlWithUtm} onClick={handleClick}>
        <h3 className="--line-truncate title">{investingInsight.title}</h3>
        <SpacerY size={8} />
        <p className="--line-truncate body">{investingInsight.body}</p>
        <SpacerY size={8} />
        <div className="--flex-x-center --truncate sub-text">
          {investingInsight.platform.imageUrl && (
            <>
              <div className="--flex-shrink-off platform-image">
                <Image
                  src={investingInsight.platform.imageUrl}
                  alt={investingInsight.platform.name}
                />
              </div>
              <SpacerX size={8} />
            </>
          )}
          <span className="--truncate">{investingInsight.creator.nickname}</span>
        </div>
      </InvestingInsightsLink>
    </StyledInvestingInsightsContent>
  )
}

export const InvestingInsightsContentSkeleton = () => {
  return (
    <StyledInvestingInsightsContent>
      <div className="--flex-shrink-off image">
        <Skeleton loading borderRadius={12} />
      </div>
      <SpacerY size={20} />
      <Skeleton loading pcWidth="100%" pcHeight={20} />
      <SpacerY size={8} />
      <Skeleton loading pcWidth="100%" pcHeight={15} />
      <Skeleton loading pcWidth="50%" pcHeight={15} />
      <SpacerY size={8} />
      <Skeleton loading pcWidth="60%" pcHeight={15} />
    </StyledInvestingInsightsContent>
  )
}

export default InvestingInsightsContent
