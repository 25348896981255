import styled from 'styled-components'

const StyledSkeleton = styled.div`
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: var(--color-gray-39);
  width: 100%;
  min-width: inherit;
  height: 100%;
  min-height: inherit;
  border-radius: var(--border-radius-6);

  /* safari 에서 border-radius에 따른 overflow: hidden; 을 인식하지 못함에 대응 */
  transform: translateZ(0);

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(var(--color-gray-75-rgb), 0) 0,
      rgba(var(--color-gray-75-rgb), 0.3) 20%,
      rgba(var(--color-gray-75-rgb), 0.6) 60%,
      rgba(var(--color-gray-75-rgb), 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(150%);
    }
  }
`

export default StyledSkeleton
