import * as React from 'react'

import { InvestingInsightTagFieldsFragment } from '@/gql/generated-api1'
import Skeleton from '@/components/common/skeleton'
import { SpacerX } from '@/design-system/spacer'

import InvestingInsightsNavItem from '../investing-insights-nav-item'

import StyledInvestingInsightsNav from './styled'

type Props = {
  loading: boolean
  tagId?: InvestingInsightTagFieldsFragment['id'] | null
  investingInsightTags?: InvestingInsightTagFieldsFragment[]
  onTagClick: (tagId: InvestingInsightTagFieldsFragment['id']) => void
  isShowMoreLoading: boolean
  moreLoadingElRef?: React.RefObject<HTMLLIElement>
}

const skeleton = <Skeleton loading pcWidth={100} pcHeight={34} borderRadius={12} />

const InvestingInsightsNav = ({
  loading,
  tagId,
  investingInsightTags,
  onTagClick,
  isShowMoreLoading,
  moreLoadingElRef,
}: Props) => {
  return (
    <StyledInvestingInsightsNav className="--flex-x-center --horizontal-scroll">
      <SpacerX size={8} mobileOnly />
      {loading && !investingInsightTags?.length && (
        <>
          <li ref={moreLoadingElRef}>{skeleton}</li>
          <li ref={moreLoadingElRef}>
            <Skeleton loading pcWidth={90} pcHeight={34} borderRadius={12} />
          </li>
          <li ref={moreLoadingElRef}>
            <Skeleton loading pcWidth={70} pcHeight={34} borderRadius={12} />
          </li>
        </>
      )}
      {investingInsightTags?.map((tag) => (
        <li key={tag.id} className="--flex-shrink-off">
          <InvestingInsightsNavItem
            active={tag.id === tagId}
            investingInsightTag={tag}
            onClick={onTagClick}
          />
        </li>
      ))}
      {isShowMoreLoading && <li ref={moreLoadingElRef}>{skeleton}</li>}
      <SpacerX size={8} mobileOnly />
    </StyledInvestingInsightsNav>
  )
}

export default InvestingInsightsNav
